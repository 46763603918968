import React from "react";
import { View, ViewStyle } from "react-native";
import { createIconSet } from "./createIconSet";
import mapValues from "lodash/mapValues";
import codeMap from "shared/assets/icons/icons.json";

const glyphMap = mapValues(codeMap, (value) =>
  String.fromCharCode(parseInt(value.replace("\\", ""), 16))
);

const CustomIcon = createIconSet(glyphMap, "icons", "icons.ttf");

export type IconName = keyof typeof codeMap;

interface IconProps {
  name: IconName;
  size: number;
  color: any;
  style?: ViewStyle;
}

function Icon(props: IconProps) {
  return (
    <View accessibilityElementsHidden={true} accessibilityLabel="">
      <CustomIcon {...props} />
    </View>
  );
}

export { Icon };
