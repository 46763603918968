import Constants from "expo-constants";

function urlKeyToID(urlKey) {
  const clean = urlKey.slice(0, 1) + urlKey.slice(2);
  const id = parseInt(clean, 36);
  return id;
}

function idToURLKey(id) {
  const s = Number(id).toString(36);
  const urlKey = s.slice(0, 1) + "q" + s.slice(1);
  return urlKey;
}

const identity = (id) => id;
function swap(json) {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
}

const transformURLKeyToID = {
  occurrence: urlKeyToID,
};

const transformIDToURLKey = {
  occurrence: idToURLKey,
};

const pathMap = {
  e: "occurrence",
  p: "profile",
  page: "page",
  "/": "home",
  "app/explore": "explore",
  "app/saved": "saved",
  calendar: "categories",
  "app/collage": "collage",
  profileList: "profileList",
  "app/timeline": "timeline",
  "app/me": "settings",
  "app/notifications": "notificationCenter",
};

const resourceMap = swap(pathMap);

function parseKULTAURL(url) {
  const parts = url.split("/");
  const paths = Object.keys(pathMap);
  let foundPath;
  /** @type {number} */
  let foundIndex;
  paths.forEach((p) => {
    const index = parts.findIndex((item) => item === p);
    if (index !== -1 && !foundPath) {
      foundPath = p;
      foundIndex = index;
    }
  });
  if (!foundPath) {
    throw new Error("Cannot parse as KULTA URL");
  }
  const resourceType = pathMap[foundPath];
  const resourceId = parts[foundIndex + 1];
  const transformer = identity;
  return {
    resourceType,
    resourceId: resourceId ? transformer(resourceId) : "",
  };
}

function makeKULTAURL({ resourceId, resourceType }) {
  if (resourceType === "profile") {
    return `https://${resourceId}.kulta.link`;
  } else {
    return `${Constants.expoConfig?.extra?.websiteApp}/${makeKULTAURLRelative({
      resourceId,
      resourceType,
    })}`;
  }
}

function getURLBase() {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  } else {
    return Constants.expoConfig?.extra?.webDomain;
  }
}

export function getLegacyEditURLForOccurrence(id) {
  return `https://publish.kulta.app/#/_events_list_veranstalter/${id}`;
}

export function getLegacyEditURLForProfile(id) {
  return `https://publish.kulta.app/#/profiles/${id}`;
}

function makeKULTAURLRelative({ resourceId, resourceType }) {
  const transformer = transformIDToURLKey[resourceType] || identity;
  const pathSegment = resourceMap[resourceType];
  const urlKey = resourceId ? transformer(resourceId) : "";
  const url = `/${pathSegment}/${urlKey}`;
  return url;
}

export {
  getURLBase,
  parseKULTAURL,
  makeKULTAURL,
  makeKULTAURLRelative,
  urlKeyToID,
  idToURLKey,
};
