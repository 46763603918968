import React from "react";
import { TouchableOpacityProps, Pressable } from "react-native";
import "./Touchable.css";

const Touchable: React.FC<TouchableOpacityProps> = (props) => {
  return (
    <Pressable
      style={({ pressed }) => ({ opacity: pressed ? 0.7 : 1 })}
      accessibilityRole="button"
      testID="touchable"
      {...props}
    />
  );
};

export { Touchable };
