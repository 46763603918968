import React from "react";
import {
  TextProps,
  Text as RNText,
  TextStyle,
  StyleProp,
  TextInput,
  TextInputProps,
  View,
} from "react-native";
import { Colors, Dynamic, Spacing } from "shared/utils/theme";
import { StyleSheet, Platform } from "react-native";
import {
  material,
  human,
  iOSUIKit,
  sanFranciscoSpacing,
  systemWeights,
} from "react-native-typography";
import { lightDark } from "shared/utils/theme";
import { Icon, IconName } from "../Icon";
import { Button } from "shared/components/Button";

let textStyles: { [key in TextStyleType]: any };

export type TextStyleType =
  | "almostHuge"
  | "huge"
  | "largeTitle"
  | "title"
  | "longTitle"
  | "smallTitle"
  | "subtitleLight"
  | "subtitle"
  | "subtitleStrong"
  | "caption"
  | "captionStrong"
  | "captionLight"
  | "button"
  | "titleBar"
  | "body"
  | "bodyStrong"
  | "tiny"
  | "tinyStrong";

if (Platform.OS === "web") {
  textStyles = StyleSheet.create({
    huge: {
      fontWeight: "bold",
      color: Colors.primary,
      fontSize: 56,
      letterSpacing: sanFranciscoSpacing(64),
    },
    almostHuge: {
      fontWeight: "bold",
      color: Colors.primary,
      fontSize: 42,
      letterSpacing: sanFranciscoSpacing(48),
    },
    largeTitle: {
      ...iOSUIKit.largeTitleEmphasizedObject,
      color: Colors.primary,
    },
    title: {
      ...human.title1Object,
      ...systemWeights.semibold,
      color: Colors.primary,
    },
    longTitle: {
      ...human.title2Object,
      ...systemWeights.bold,
      color: Colors.primary,
    },
    smallTitle: { ...human.headlineObject, color: Colors.primary },
    subtitleLight: {
      ...human.subheadObject,
      color: lightDark(Colors.greyscale[2], Colors.greyscale[5]),
      letterSpacing: sanFranciscoSpacing(13),
    },
    subtitle: {
      ...human.subheadObject,
      letterSpacing: sanFranciscoSpacing(13),
      color: Colors.primary,
    },
    subtitleStrong: {
      ...human.subheadObject,
      fontWeight: "bold",
      letterSpacing: sanFranciscoSpacing(13),
      color: Colors.primary,
    },
    caption: {
      ...human.footnoteObject,
      color: lightDark(Colors.greyscale[2], Colors.greyscale[5]),
    },
    captionStrong: {
      ...human.footnoteObject,
      fontWeight: "bold",
      color: Colors.primary,
    },
    captionLight: {
      ...human.footnoteObject,
      color: lightDark(Colors.greyscale[3], Colors.greyscale[4]),
    },
    body: {
      ...human.bodyObject,
      color: lightDark(Colors.greyscale[3], Colors.greyscale[4]),
      letterSpacing: sanFranciscoSpacing(20),
    },
    bodyStrong: {
      ...human.bodyObject,
      color: lightDark(Colors.greyscale[1], Colors.greyscale[6]),
      fontWeight: "bold",
      letterSpacing: sanFranciscoSpacing(20),
    },
    titleBar: { ...human.headlineObject, color: Colors.primary },
    button: { ...iOSUIKit.subheadEmphasizedObject },
    tiny: {
      ...human.footnoteObject,
      fontSize: 11,
      color: Colors.primary,
    },
    tinyStrong: {
      ...human.footnoteObject,
      fontSize: 11,
      fontWeight: "bold",
      color: Colors.primary,
    },
  });
} else if (Platform.OS === "ios") {
  textStyles = StyleSheet.create({
    huge: {
      fontWeight: "bold",
      color: Colors.primary,
      fontSize: 64,
      letterSpacing: sanFranciscoSpacing(64),
    },
    almostHuge: {
      fontWeight: "bold",
      color: Colors.primary,
      fontSize: 48,
      letterSpacing: sanFranciscoSpacing(48),
    },
    largeTitle: {
      ...iOSUIKit.largeTitleEmphasizedObject,
      color: Colors.primary,
    },
    title: {
      ...human.title1Object,
      ...systemWeights.semibold,
      color: Colors.primary,
    },
    longTitle: {
      ...human.title2Object,
      ...systemWeights.bold,
      color: Colors.primary,
    },
    smallTitle: { ...human.headlineObject, color: Colors.primary },
    subtitleLight: {
      ...human.subheadObject,
      color: lightDark(Colors.greyscale[2], Colors.greyscale[5]),
      letterSpacing: sanFranciscoSpacing(13),
    },
    subtitle: {
      ...human.subheadObject,
      letterSpacing: sanFranciscoSpacing(13),
      color: Colors.primary,
    },
    subtitleStrong: {
      ...human.subheadObject,
      fontWeight: "bold",
      letterSpacing: sanFranciscoSpacing(13),
      color: Colors.primary,
    },
    caption: {
      ...human.footnoteObject,
      color: lightDark(Colors.greyscale[2], Colors.greyscale[5]),
    },
    captionStrong: {
      ...human.footnoteObject,
      fontWeight: "bold",
      color: Colors.primary,
    },
    captionLight: {
      ...human.footnoteObject,
      color: lightDark(Colors.greyscale[3], Colors.greyscale[4]),
    },
    body: {
      ...human.bodyObject,
      color: lightDark(Colors.greyscale[3], Colors.greyscale[4]),
      letterSpacing: sanFranciscoSpacing(20),
    },
    bodyStrong: {
      ...human.bodyObject,
      color: lightDark(Colors.greyscale[1], Colors.greyscale[6]),
      fontWeight: "bold",
      letterSpacing: sanFranciscoSpacing(20),
    },
    titleBar: { ...human.headlineObject, color: Colors.primary },
    button: { ...iOSUIKit.subheadEmphasizedObject },
    tiny: {
      ...human.footnoteObject,
      fontSize: 11,
      color: Colors.primary,
    },
    tinyStrong: {
      ...human.footnoteObject,
      fontSize: 11,
      fontWeight: "bold",
      color: Colors.primary,
    },
  });
} else if (Platform.OS === "android") {
  textStyles = StyleSheet.create({
    huge: {
      ...material.display1Object,
      color: Colors.primary,
      fontSize: 40,
    },
    almostHuge: {
      fontWeight: "bold",
      color: Colors.primary,
      fontSize: 48,
    },
    largeTitle: {
      ...material.titleObject,
      fontSize: 22,
      color: Colors.primary,
    },
    title: {
      ...human.title1Object,
      ...systemWeights.semibold,
      color: Colors.primary,
    },
    longTitle: {
      ...material.titleObject,
      color: Colors.primary,
    },
    smallTitle: {
      ...material.subheadingObject,
      fontWeight: "bold",
      lineHeight: 22,
      color: Colors.primary,
    },
    subtitleLight: {
      ...material.subheadingObject,
      lineHeight: 22,
      color: lightDark(Colors.greyscale[2], Colors.greyscale[5]),
    },
    subtitle: {
      ...material.subheadingObject,
      lineHeight: 22,
      color: Colors.primary,
    },
    subtitleStrong: {
      ...material.subheadingObject,
      lineHeight: 22,
      fontWeight: "bold",
      color: Colors.primary,
    },
    caption: {
      ...material.body2Object,
      color: lightDark(Colors.greyscale[2], Colors.greyscale[5]),
    },
    captionStrong: {
      ...material.body2Object,
      fontWeight: "bold",
      color: Colors.primary,
    },
    captionLight: {
      ...material.body2Object,
      color: lightDark(Colors.greyscale[3], Colors.greyscale[4]),
    },
    body: {
      ...material.body2Object,
      fontSize: 16,
      color: lightDark(Colors.greyscale[3], Colors.greyscale[5]),
    },
    bodyStrong: {
      ...material.body2Object,
      fontSize: 16,
      color: lightDark("black", "white"),
      fontWeight: "bold",
    },
    titleBar: { ...material.subheadingObject, color: Colors.primary },
    button: { ...material.buttonObject },
    tiny: {
      ...material.captionObject,
      fontSize: 11,
      color: Colors.primary,
    },
    tinyStrong: {
      ...material.captionObject,
      fontSize: 11,
      fontWeight: "bold",
      color: Colors.primary,
    },
  });
}

interface KTextProps extends TextProps, TextInputProps {
  type?: TextStyleType;
  color?: string;
  heading?: boolean;
  style?: StyleProp<TextStyle>;
  edit?: boolean;
  dataSet?: any;
  editIcon?: IconName;
  editUI?: boolean;
  onStartEdit?: () => void;
  onFinishEdit?: () => void;
  onCancelEdit?: () => void;
}

const Text: React.FC<KTextProps> = ({
  allowFontScaling = false,
  type,
  color = Colors.primary,
  style,
  edit = false,
  children = undefined,
  editIcon = undefined,
  editUI = false,
  heading = type === "largeTitle" || type === "longTitle" || type === "title",
  onCancelEdit,
  onStartEdit,
  onFinishEdit,
  ...rest
}) => {
  if (edit) {
    return (
      <View>
        <View style={{ zIndex: 5 }}>
          <TextInput
            placeholderTextColor={Colors.greyscale[4]}
            multiline={rest.numberOfLines && rest.numberOfLines > 1}
            style={[
              {
                backgroundColor: Colors.bg.input,
                borderRadius: 4,
              },
              type ? textStyles[type] : [],
              color === "inherit"
                ? { color: undefined }
                : color
                ? { color }
                : {},
              style,
              { paddingLeft: editIcon ? 34 : undefined },
            ]}
            value={children as string}
            {...rest}
          />
        </View>
        <View style={{ zIndex: 10, position: "absolute", top: 10, left: 8 }}>
          {editIcon && (
            <Icon name={editIcon} size={20} color={Colors.primary} />
          )}
        </View>
        {editUI ? (
          <View
            style={{
              zIndex: 10,
              position: "absolute",
              bottom: -20,
              right: 25,
              width: 100,
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Button
                iconLeft="f-chevron-left"
                background={Colors.greyscale[3]}
                style={{ marginRight: Spacing.betweenHalf }}
                size="compact"
                onPress={onCancelEdit}
              />
              <Button
                size="compact"
                background={Colors.accent}
                onPress={onFinishEdit}
                children="Speichern"
              />
            </View>
          </View>
        ) : null}
      </View>
    );
  } else if (editUI) {
    return (
      <View>
        <RNText
          style={[
            type ? textStyles[type] : [],
            color === "inherit" ? { color: undefined } : color ? { color } : {},
            style,
          ]}
          children={children}
          accessibilityRole={heading ? "header" : "text"}
          allowFontScaling={allowFontScaling}
          {...rest}
        />
        <View
          style={{
            position: "absolute",
            top: -2,
            right: -60,
          }}
        >
          <Button
            onPress={onStartEdit}
            iconLeft="f-edit-2"
            pill
            background={Colors.accent}
          />
        </View>
      </View>
    );
  } else {
    return (
      <RNText
        style={[
          type ? textStyles[type] : [],
          color === "inherit" ? { color: undefined } : color ? { color } : {},
          style,
        ]}
        children={children}
        accessibilityRole={heading ? "header" : "text"}
        allowFontScaling={allowFontScaling}
        {...rest}
      />
    );
  }
};

export { Text };
