import React, { useState } from "react";
import {
  View,
  ActivityIndicator,
  TouchableWithoutFeedbackProps,
} from "react-native";
import { Colors, Radius, Spacing } from "shared/utils/theme";
import { Text } from "shared/components/Text";
import { Icon, IconName } from "shared/components/Icon";
import { Touchable } from "shared/components/Touchable";
import { useStore } from "shared/utils/store";
import { Link, ResourceType } from "../Link";

interface ButtonProps extends TouchableWithoutFeedbackProps {
  invert?: boolean;
  outline?: boolean;
  background?: string;
  foreground?: string;
  iconColor?: string;
  textStyle?: string;
  compact?: boolean;
  iconLeft?: IconName;
  iconRight?: IconName;
  disabled?: boolean;
  isNetworkAction?: boolean;
  pill?: boolean;
  size?: "compact" | "medium" | "big";
  resourceType?: ResourceType;
  resourceId?: string;
  as?: any;
}

const Button: React.FC<ButtonProps> = ({
  invert = false,
  outline = false,
  background = Colors.transparent.primary[3],
  foreground = Colors.generic.white,
  iconColor = foreground,
  size = "medium",
  children,
  iconLeft,
  iconRight,
  disabled = false,
  isNetworkAction = false,
  textStyle = size === "big" ? "smallTitle" : "button",
  pill = false,
  onPress,
  as: Component = Touchable,
  ...rest
}) => {
  const online = useStore((state) => state.online);
  const showDisabled = disabled || (isNetworkAction && !online);
  const outl = outline ? 0 : 2;
  const [showLoading, setShowLoading] = useState(false);
  const shouldHideLoading = React.useRef(false);

  if (isNetworkAction) {
    if (showLoading && shouldHideLoading.current === true) {
      setShowLoading(false);
    } else {
      shouldHideLoading.current = true;
    }
  }
  const ButtonComponent = rest.resourceType ? Link : Touchable;
  return (
    <ButtonComponent
      accessibilityRole={"button"}
      accessibilityState={{ disabled: showDisabled }}
      disabled={showDisabled}
      onPress={
        rest.resourceType
          ? undefined
          : (e) => {
              if (!showDisabled && !showLoading) {
                onPress(e);
                if (isNetworkAction) {
                  setShowLoading(true);
                  shouldHideLoading.current = false;
                }
              }
            }
      }
      resourceType={rest.resourceType ?? "home"}
      {...rest}
    >
      <View
        style={{
          paddingVertical:
            (size === "compact"
              ? 4
              : size === "big"
              ? Spacing.betweenHalf + 4
              : pill && !children
              ? Spacing.betweenHalf + 4
              : Spacing.betweenHalf) + outl,
          paddingLeft:
            (size === "compact"
              ? 4
              : size === "big"
              ? Spacing.between
              : Spacing.betweenHalf + 4) + outl,
          paddingRight:
            iconLeft && children
              ? 4
              : (size === "compact"
                  ? 4
                  : size === "big"
                  ? Spacing.between
                  : Spacing.betweenHalf + 4) + outl,
          backgroundColor: invert ? foreground : background,
          borderRadius: pill
            ? 100
            : size === "compact"
            ? Radius.medium
            : size === "big"
            ? Radius.round
            : Radius.medium,
          borderWidth: outline ? (size === "big" ? 3 : 2) : 0,
          borderColor: invert ? background : foreground,
          flexDirection: "row",
          alignItems: "center",
          opacity: showDisabled ? 0.3 : 1,
        }}
      >
        <View>
          {iconLeft &&
            (showLoading ? (
              <ActivityIndicator
                style={{
                  // @ts-ignore
                  color: invert ? background : foreground,
                }}
                size={size === "big" ? 24 : 20}
              />
            ) : (
              <Icon
                name={iconLeft}
                size={size === "big" ? 24 : size === "compact" ? 20 : 20}
                color={invert ? background : iconColor}
              />
            ))}
        </View>
        <View style={{ flex: 1 }} />
        {!iconLeft && showLoading ? (
          <ActivityIndicator color={invert ? background : foreground} />
        ) : (
          <Text
            type={textStyle as unknown as any}
            style={{
              color: invert ? background : foreground,
            }}
          >
            {children}
          </Text>
        )}
        <View style={{ flex: 1 }} />
        <View>
          {iconRight && (
            <Icon
              style={{ marginRight: 4 }}
              name={iconRight}
              size={size === "big" ? 24 : 20}
              color={invert ? background : iconColor}
            />
          )}
        </View>
      </View>
    </ButtonComponent>
  );
};

export { Button };
