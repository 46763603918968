import React, { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import NextLink from "next/link";
import {
  LinkProps,
  ResourceType as TResourceType,
  Linkable as TLinkable,
} from "./types";
import {
  parseKULTAURL,
  makeKULTAURLRelative,
  makeKULTAURL,
} from "shared/utils/kultaURL";
import { A } from "shared/components/HTML";
import { normalizeExternalURL } from "shared/utils/normalizeExternalURL";

export type ResourceType = TResourceType;
export type Linkable = TLinkable;

function openScreen(
  resourceType: ResourceType,
  resourceId: string,
  screenId: string = ""
) {
  if (typeof window !== "undefined") {
    window.location.href = makeKULTAURL({ resourceType, resourceId });
  }
}

function openAppLink(appLink) {
  const { resourceType, resourceId } = parseKULTAURL(appLink);
  openScreen(resourceType, resourceId);
}

const styles = StyleSheet.create({
  link: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
});

function Link({
  resourceType,
  resourceId,
  as: ComponentType = "a",
  screenTitle,
  passProps,
  children,
  prefetch,
  modal = false,
  replace = false,
  track = undefined,
  onPress: onPressProp = () => {},
  style,
  accessibilityHint,
  ...rest
}: LinkProps) {
  const { href, target, rel } = useMemo(() => {
    let href = "";
    let target = "";
    let rel = "";
    if (modal && resourceType !== "tel") {
      target = "_blank";
      rel = "norel nofollow";
    }
    if (resourceType === "url") {
      href = normalizeExternalURL(resourceId);
    } else if (resourceType === "tel") {
      href = `tel:${resourceId.trim()}`;
    } else if (resourceType === "nextLink") {
      href = resourceId;
    } else {
      href = makeKULTAURLRelative({ resourceId, resourceType });
    }
    return { href, target, rel };
  }, [modal, resourceId, resourceType]);
  const onPressHandler = useCallback(
    (e) => {
      onPressProp && onPressProp(e);
      if (resourceType === "tel") {
        const show = window.confirm(`${resourceId}\n\nNummber anrufen?`);
        if (!show) {
          e.preventDefault();
        }
      }
    },
    [onPressProp]
  );

  if (["tel", "url"].includes(resourceType)) {
    return (
      <A
        onClick={onPressHandler}
        href={href}
        target={target}
        rel={rel}
        // @ts-ignore
        style={[styles.link, style]}
        {...rest}
      >
        {children}
      </A>
    );
  } else {
    return (
      <NextLink replace={replace} href={href} {...rest}>
        <a
          onClick={onPressHandler}
          target={target}
          rel={rel}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            ...style,
          }}
        >
          {children}
        </a>
      </NextLink>
    );
  }
}

export { Link, openScreen, openAppLink };
